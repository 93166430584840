.vertical-menu .h-100 {
  overflow: auto;

  &::-webkit-scrollbar {
    width: 6px;
  }

  &::-webkit-scrollbar-track {
    background: transparent;
  }

  &::-webkit-scrollbar-thumb {
    border-radius: 20px;
    border: 1px solid transparent;
    background-color: #a6b0cf;
  }

  -webkit-tap-highlight-color: transparent;
  //noinspection CssUnknownProperty
  scrollbar-width: thin;
  //noinspection CssUnknownProperty
  scrollbar-color: #a6b0cf transparent;
}

body.sidebar-enable.vertical-collpsed {
  .vertical-menu .h-100 {
    overflow: initial;
  }
}

@media only screen and (max-width: 1024px) {
  .navbar-brand-box {
    width: auto !important;
  }

  .navbar-brand-box .logo-light {
    display: none !important;
  }

  .vertical-menu {
    display: none;
  }

  body.sidebar-enable .vertical-menu {
    display: block;
  }

  .main-content {
    margin-left: 0 !important;
  }
}
