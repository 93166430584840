.work-order {
  min-height: 100vh;
  width: 100%;
  display: flex;
  flex-direction: column;
  background-color: white;
  padding: 24px 96px;
}

.work-order__header {
  display: flex;
}

.work-order__header__section {
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  &.stretch {
    flex: 1;
  }
}

.work-order__header__img {
  height: 64px;
  margin-bottom: 8px;
}

.work-order__header__company-section {
  i {
    margin-right: 4px;
  }
}

.work-order__order-info {
  table-layout: fixed;
  border-collapse: collapse;
  margin: 16px 0;
  border: 1px solid #ddd;

  tr, td {
    border: 1px solid #ddd;
    padding: 8px;
  }
}

.work-order__order-info__header {
  font-weight: bold;
}

.work-order__order-info__items {
  display: flex;
  flex-direction: column;
}

.work-order__products_group-header > td {
  background: #ddd !important;
  color: black;
  padding: 10px 16px !important;

  &:hover {
    background: #ddd !important;
    color: black;
  }
}

.work-order__instructions {
  margin-top: 16px;
  display: flex;
  flex-direction: column;
}

.work-order__no-data {
  height: 100vh;
  width: 100%;
  padding: 64px;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  & h4 {
    text-align: center;
  }
}

@media only print {
  .work-order {
    padding: 0;
  }
  .work-order__column-name {
    text-overflow: ellipsis;
    max-width: 550px;
  }
}
