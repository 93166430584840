.ant-notification {
  z-index: 1050;
}

body {
  background-color: #f8f8fb;
  overflow-x: hidden;
}

.text-muted {
  color: #74788d
}
