.cut-list-details__dl{
  margin: 0;
}

.cut-list-details__dd{
  display: flex;
}

.cut-list-details__sheet{
  margin-right: 5px
}
